
import { defineComponent }      from '@vue/composition-api';
import CAddTaskButton           from '@/components/common/add-task-button/add-task-button.component.vue';
import { iconTypes, MainType }  from '@/types';
import { ref, Ref }             from 'vue';
import { useGlobals }           from '@/hooks/use-globals';
import { name as vCreateIssue } from '@/views/createIssue/createIssue.route';

export default defineComponent({
  name: 'CAddTaskButtonGroup',
  components: {
    CAddTaskButton,
  },
  emits: [ 'update:buttonsExpanded', 'addTask' ],
  setup() {
    const { store,router } = useGlobals();
    const buttonsExpanded: Ref<boolean> = ref(false);
    const addTask = async (taskType: MainType): Promise<void> => {
      await router.push({ name: vCreateIssue });
      store.commit('views/vCreateIssue/setSelectedIssueType', taskType)
      buttonsExpanded.value = false;
    };
    return {
      buttonsExpanded,
      iconTypes,
      MainType,
      addTask,
    };
  },
});
