
import { defineComponent, onMounted, onUnmounted, PropType, ref, Ref } from 'vue';
import { useGlobalTranslations }                                       from '@/hooks/use-translations';
import CButton                                                         from '@/components/common/button/button.component.vue';
import CLinkedIssuesNavigation                                         from '@/views/issues/components/issues-edit/linked-issues/components/linked-issues-navigation.component.vue';
import CLinkedIssuesList                                               from '@/views/issues/components/issues-edit/linked-issues/components/linked-issues-list.component.vue';
import CLinkedIssuesSelected                                           from '@/views/issues/components/issues-edit/linked-issues/components/linked-issues-selected.component.vue';
import { LinkedIssuesNavigation }                                      from '@/views/issues/components/issues-details/issue-details.types';
import { IssueRelatedIssues }                                          from '@/views/issues/issues.types';
import { useGlobals }                                                  from '@/hooks/use-globals';
import { useIssues, UseIssuesContext }                                 from '@/hooks/use-issues';

export default defineComponent({
  name: 'CLinkedIssues',
  components: { CLinkedIssuesSelected, CLinkedIssuesList, CButton, CLinkedIssuesNavigation },
  props: {
    relatedIssues: {
      type: Array as PropType<IssueRelatedIssues[]>,
      required: true,
    },
  },
  emits: [ 'save' ],
  setup(props, { emit }) {
    const { store } = useGlobals();
    const { getAllIssues } = useIssues(UseIssuesContext.ISSUES);
    const activeNavigation: Ref<LinkedIssuesNavigation> = ref(LinkedIssuesNavigation.SELECTED);
    const component: any = ref(null);
    const fixedHeight: Ref<string> = ref('');
    const linkedIssues: Ref<IssueRelatedIssues[]> = ref(props.relatedIssues);
    const changeNavigation = (navigation: LinkedIssuesNavigation): void => {
      activeNavigation.value = navigation;
    };

    const getComponent = () => {
      switch (activeNavigation.value) {
        case LinkedIssuesNavigation.SEARCH:
          return 'c-linked-issues-list';
        case LinkedIssuesNavigation.SELECTED:
          return 'c-linked-issues-selected';
      }
    };

    const getFixedHeight = (): void => {
      const componentWrapper = component.value?.$el as unknown as HTMLElement;
      const headerHeight = 90;
      const height = document.body.offsetHeight - 2 * componentWrapper.offsetTop - headerHeight;
      fixedHeight.value = `${height}px`;
    };

    const saveRelatedIssues = () => {
      const relatedIssues = linkedIssues.value.map((issue) => {
        return { name: issue.name, issueNumber: issue.issueNumber, uuid: issue.uuid };
      });
      emit('save', relatedIssues);
    };

    onMounted(() => {
      window.addEventListener('resize', getFixedHeight);
      getFixedHeight();
    });
    onUnmounted(() => {
      window.removeEventListener('resize', getFixedHeight);
      store.commit('setIssueParamsSearchQuery', '');
      getAllIssues();
    });

    return {
      fixedHeight,
      component,
      activeNavigation,
      linkedIssues,
      saveRelatedIssues,
      changeNavigation,
      getComponent,
      useGlobalTranslations,
    };
  },
});
