import { ValidationRuleWithoutParams, ValidationRuleWithParams } from '@vuelidate/core';

export interface Rules { noteInput : {required: ValidationRuleWithoutParams, maxLength: ValidationRuleWithParams<{max:number}, unknown>}}

export enum IssueDetailsNavigation {
  NOTES = 'NOTES',
  INFORMATION = 'INFORMATION'
}

export enum LinkedIssuesNavigation {
  SELECTED = 'SELECTED',
  SEARCH = 'SEARCH'
}