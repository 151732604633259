import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_back = _resolveComponent("icon-back")

  return (_openBlock(), _createBlock("div", {
    class: "flex items-center cursor-pointer outline-none",
    tabindex: "1",
    onFocusin: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isFocused=true)),
    onFocusout: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isFocused=false)),
    onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.$emit('click')), ["enter"]))
  }, [
    _createVNode(_component_icon_back),
    _createVNode("span", {
      class: ["   ml-f5 text-14 font-bold", [_ctx.isFocused?'text-gray-800' : 'text-back-button']]
    }, _toDisplayString(_ctx.useGlobalTranslations('button.back')), 3)
  ], 32))
}