
import { defineComponent, onUpdated, PropType, Ref, ref } from 'vue';
import { IssueRelatedIssues }                             from '@/views/issues/issues.types';
import IconClose                                          from '@/components/icons/icon-close.component.vue';

export default defineComponent({
  name: 'CLinkedIssuesSelected',
  components: { IconClose },
  props: {
    relatedIssues: {
      type: Array as PropType<IssueRelatedIssues[]>,
      required: true,
    },
    details: {
      type: Boolean,
      default: false,
    },
  },
  emits: [ 'update-related' ],
  setup(props, { emit }) {
    const linkedIssues: Ref<IssueRelatedIssues[]> = ref([ ...props.relatedIssues ]);
    const removeIssue = (issue: IssueRelatedIssues) => {
      linkedIssues.value = linkedIssues.value.filter((linkedIssue: IssueRelatedIssues) => linkedIssue.uuid !== issue.uuid);
      emit('update-related', linkedIssues.value);
    };

    onUpdated(() => {
      linkedIssues.value = [ ...props.relatedIssues ];
    });

    return {
      linkedIssues,
      removeIssue,
    };
  },
});
