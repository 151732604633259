
import { defineComponent }  from '@vue/composition-api';
import IconMaintenance      from '@/components/icons/icon-maintenance.component.vue';
import IconInsurance        from '@/components/icons/icon-insurance.component.vue';
import IconPlus             from '@/components/icons/icon-plus.component.vue';
import { iconTypes }        from '@/types';
import { computed, toRefs } from 'vue';
import { useGlobalTranslations } from '@/hooks/use-translations';


export default defineComponent({
  name: 'CAddTaskButton',
  components: {
    IconMaintenance,
    IconInsurance,
    IconPlus,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    addLabel: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      icon,
      open,
    } = toRefs(props);

    const getClasses = computed(() => {
      if (icon.value === iconTypes.MAINTENANCE) {
        return open.value ? ['block bg-primary-600 animate-secondIconUp'] : ['bg-primary-600 animate-secondIconDown'];
      }
      if (icon.value === iconTypes.INSURANCE) {
        return open.value ? ['block bg-primary-600 animate-iconUp'] : ['bg-primary-600 animate-iconDown'];
      }
      if (icon.value === iconTypes.PLUS) {
        return open.value ? ['bg-gray-150 animate-roll '] : ['bg-primary-600 animate-rollBack'];
      }
      return [];
    });

    return {
      getClasses,
      iconTypes,
      useGlobalTranslations
    };
  },
})
;
