import { withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  key: 0,
  ref: "panel",
  class: "h-screen sm:h-withoutHeader max-h-screen flex flex-col p-8 z-2001\n           absolute top-0 md:top-header overflow-y-scroll scrollbar-thin scrollbar-thumb-primary-500 scrollbar-track-primary-200\n           right-0 md:bottom-0 top-0 min-h-540 md:w-1/4 sm:w-2/3 bg-white rounded-lg rounded-r-none shadow-sidePanel shadow-t-none focus:outline-none",
  tabindex: "0"
}
const _hoisted_2 = { class: "c-side-panel__header inline-flex items-center w-full" }
const _hoisted_3 = { class: "text-primary-600 font-sans font-bold mx-f13 text-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_close_button = _resolveComponent("c-close-button")

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    (_ctx.isVisible)
      ? (_openBlock(), _createBlock("div", {
          key: 0,
          class: "bg-black opacity-50 absolute md:top-header top-0 bottom-0 w-screen z-50",
          onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closePanel && _ctx.closePanel(...args)), ["self"]))
        }))
      : _createCommentVNode("", true),
    _createVNode(_Transition, {
      "enter-active-class": "transition ease-out duration-400",
      "enter-from-class": "transform translate-x-full",
      "enter-to-class": "transform translate-x-0",
      "leave-active-class": " transition ease-in duration-400",
      "leave-from-class": "transform translate-x-0",
      "leave-to-class": "transform translate-x-full"
    }, {
      default: _withCtx(() => [
        (_ctx.isVisible)
          ? (_openBlock(), _createBlock("div", _hoisted_1, [
              _createVNode("header", _hoisted_2, [
                _renderSlot(_ctx.$slots, "header", {}, () => [
                  _createVNode(_component_c_close_button, {
                    class: "m-f8",
                    onClick: _ctx.closePanel
                  }, null, 8, ["onClick"]),
                  _createVNode("p", _hoisted_3, _toDisplayString(_ctx.title), 1)
                ])
              ]),
              _renderSlot(_ctx.$slots, "default")
            ], 512))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}