import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.steps, (index) => {
      return (_openBlock(), _createBlock("div", {
        key: index,
        class: ["rounded-full mr-2 h-2 w-2 transition-all duration-300", [_ctx.activeStep===index ? 'bg-primary-600 h-4 w-4' :'bg-gray-icon']]
      }, null, 2))
    }), 128))
  ]))
}