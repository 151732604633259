
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CFormSteps',
  props: {
    steps: {
      type: Number,
      default: 4
    },
    activeStep: {
      type: Number,
      default: 1
    }
  },
  emits: ['goToPage', 'update:activeStep']
})
