
import { computed, ComputedRef, defineComponent, PropType, ref, Ref } from 'vue';
import { useGlobals }                                                 from '@/hooks/use-globals';
import { ListIssue }                                                  from '@/views/issues/issues.types';
import { useGlobalTranslations }                                      from '@/hooks/use-translations';
import { useIssues, UseIssuesContext }                                from '@/hooks/use-issues';
import { IssueFilterType }                                            from '@/store/store.types';
import CLinkedIssuesFilters
  from '@/views/issues/components/issues-edit/linked-issues/components/linked-issues-filters.component.vue';

export default defineComponent({
  name: 'CLinkedIssuesList',
  components: { CLinkedIssuesFilters },
  props: {
    relatedIssues: {
      type: Array as PropType<ListIssue[]>,
      required: true,
    },
  },
  emits: [ 'update-related' ],
  setup(props, { emit }) {
    const { store } = useGlobals();
    const { getAllIssues } = useIssues(UseIssuesContext.ISSUES);
    const issues: ComputedRef<ListIssue[]> = computed(() => store.state.issues.issues.filter((issue: ListIssue) => {
      return (
        !props.relatedIssues.some((relatedIssue) => issue.uuid === relatedIssue.uuid) &&
          issue.uuid !== store.state.issues.selectedIssue?.uuid
      );
    }));
    const linkedIssues: Ref<ListIssue[]> = ref([ ...props.relatedIssues ]);

    const addRelatedIssue = (selectedIssue: ListIssue) => {
      linkedIssues.value.push(selectedIssue);
      emit('update-related', linkedIssues.value);
    };

    store.commit('setIssueParamsFilter', IssueFilterType.ALL);
    getAllIssues();

    const getTooltip = (issue: ListIssue) => {
      return `Number: ${issue.issueNumber}\nStatus: ${issue.status}\nFull name: ${issue.name}`;
    };

    return {
      linkedIssues,
      issues,
      getTooltip,
      addRelatedIssue,
      useGlobalTranslations,
    };
  },
});
