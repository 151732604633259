
import { defineComponent, PropType } from 'vue';
import { useGlobalTranslations } from '@/hooks/use-translations';
import { DateRange } from '@/views/issues/issues.types';

export default defineComponent({
  name: 'CIssuesSelectedFiltersDateTag',
  props: {
    filter: {
      type: Object as PropType<DateRange>,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    filterKey: {
      type: String,
      required: true,
    }
  },
  emits: ['delete-filter'],
  setup() {
    return {
      useGlobalTranslations,
    };
  },
});
