
import { defineComponent, PropType, Ref, ref } from 'vue';
import IconCalendar from '@/components/icons/icon-calendar.component.vue';
import CIconTrashCan from '@/components/icons/icon-trash-can.component.vue';

export default defineComponent({
  name: 'CFormDatePicker',
  components: { CIconTrashCan, IconCalendar },
  emit: ['clearDate'],
  props: {
    modelValue: {
      type: String as PropType<string | null>,
      required: true,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    disabledCalendar: {
      type: Boolean,
      default: false,
    },
    createIssueMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props) {
    const date: Ref<string | null> = ref(props.modelValue);
    const dayInMiliseconds = 86400000;
    const disabledDate = (time: Date) => {
      return time.getTime() + dayInMiliseconds < Date.now();
    };

    return {
      date,
      disabledDate,
    };
  },
});
