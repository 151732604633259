import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-wrap p-f5 items-center"
}
const _hoisted_2 = { class: "font-semibold text-10 sm:text-12 mr-f5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_issues_selected_filters_date_tag = _resolveComponent("c-issues-selected-filters-date-tag")
  const _component_c_issues_selected_filters_string_tags = _resolveComponent("c-issues-selected-filters-string-tags")

  return (_ctx.amountOfSelectedFilters)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("span", _hoisted_2, _toDisplayString(_ctx.useTranslations('selectedFilters', [_ctx.amountOfSelectedFilters.toString()])), 1),
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.filters, (filter, filterKey) => {
          return (_openBlock(), _createBlock("div", {
            key: filterKey,
            class: "my-f3 flex cursor-pointer"
          }, [
            ((filterKey === _ctx.SpecificFilters.CREATED_DATE_RANGE || filterKey === _ctx.SpecificFilters.DATE_RANGE) && filter)
              ? (_openBlock(), _createBlock(_component_c_issues_selected_filters_date_tag, {
                  key: 0,
                  filter: filter,
                  "filter-key": filterKey,
                  label: filterKey === _ctx.SpecificFilters.DATE_RANGE ? 'deadline' : 'created',
                  onDeleteFilter: _ctx.handleDateDelete
                }, null, 8, ["filter", "filter-key", "label", "onDeleteFilter"]))
              : (filter?.length || _ctx.SpecificFilters.OVERDUE)
                ? (_openBlock(), _createBlock(_component_c_issues_selected_filters_string_tags, {
                    key: 1,
                    filter: filterKey === _ctx.SpecificFilters.OVERDUE && filter ? [_ctx.SpecificFilters.OVERDUE] : filter,
                    "filter-key": filterKey,
                    onDeleteFilter: _ctx.handleStringDelete
                  }, null, 8, ["filter", "filter-key", "onDeleteFilter"]))
                : _createCommentVNode("", true)
          ]))
        }), 128)),
        _createVNode("span", {
          class: "text-10 sm:text-12 ml-f13 hover:text-primary-500 transition duration-600 cursor-pointer",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clearAllFilters && _ctx.clearAllFilters(...args)))
        }, _toDisplayString(_ctx.useTranslations('clearAll')), 1)
      ]))
    : _createCommentVNode("", true)
}