import { ActionTree }                                                      from 'vuex';
import { RootState }                                                       from '@/store/store.types';
import { FilterParams }                                                    from '@/views/issues/issues.types';
import { State, TaskDto }                                                  from '@/views/createIssue/createIssue.types';
import { AdministrationEndpoints, IssuesEndpoints }                        from '@/views/administration/administration.types';
import { createEntity, getSingleEntity, getTableData, updateSingleEntity } from '@/views/administration/administration.service';
import { IssueType }                                                       from '@/views/administration/views/administration-issues-types/administration-issues-types.types';
import { Notification }                                                    from '@/components/notification/notification';
import i18n                                                                from '@/i18n';
import { GLOBAL }                                                          from '@/helpers/variables';
import { MainType }                                                        from '@/types';
import { deleteFiles, getFiltersAndAllIssues }                                                     from '@/views/issues/issues.service';
import { AxiosResponse }                                                   from 'axios';


const actions: ActionTree<State, RootState> = {
  async getIssuesTypes({ commit }): Promise<void> {
    const { data } = await getTableData(AdministrationEndpoints.ISSUES_TYPES, {});
    const maintenance = [] as IssueType[];
    const care = [] as IssueType[];
    data.content.forEach((issueType: IssueType) => {
      issueType.mainType === MainType.MAINTENANCE ? maintenance.push(issueType) : care.push(issueType)
    })
    commit('setIssuesTypes', [maintenance, care]);
  },
  async getLocations({ commit }): Promise<void> {
    try {
      const { data } = await getTableData(AdministrationEndpoints.LOCATIONS, {});
      commit('setLocations', data.content);
    } catch (e){
      throw e.data;
    }
  },
  async getGroups({ commit }): Promise<void> {
    try {
      const { data } = await getTableData(AdministrationEndpoints.GROUPS, {});
      commit('setGroups', data.content);
    } catch (e){
      throw e.data;
    }
  },
  async getUsers({ commit }): Promise<void> {
    try {
      const { data } = await getTableData(AdministrationEndpoints.USERS, {});
      commit('setUsers', data.content);
      commit('setControllers', data.content);
    } catch (e){
      throw e.data;
    }
  },
  async getExpectationsTypes({ commit }): Promise<void> {
    try {
      const { data } = await getTableData(AdministrationEndpoints.EXPECTATIONS_TYPES, {});
      commit('setExpectationsTypes', data.content)
    } catch (e) {
      throw e.data
    }
  },
  async getSolutionsTypes({ commit }): Promise<void> {
    try {
      const { data } = await getTableData(AdministrationEndpoints.SOLUTIONS_TYPES, {});
      commit('setSolutionsTypes', data.content)
    } catch (e) {
      throw e.data
    }
  },

  async addTask({ dispatch, commit }, task: TaskDto): Promise<void> {
    commit('setLoading', true);
    try {
      await createEntity(IssuesEndpoints.ISSUES, task);
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('vCreateIssue.notifications.success')
      );
      commit('clearModel')
    } catch (e) {
      task.fileUuids.length && dispatch('deleteFiles', task.fileUuids)
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    } finally {
      commit('setLoading', false);
    }
  },

  async getTask({ commit }, uuid: string): Promise<any> {
    try {
      const { data } = await getSingleEntity(IssuesEndpoints.ISSUES, uuid);
      commit('setSelectedTask', data);
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async editTask(context, payload: { uuid: string, task: TaskDto }): Promise<void> {
    payload.task.mainType = context.state.selectedIssueType;
    try {
      await updateSingleEntity(IssuesEndpoints.ISSUES, payload.uuid, payload.task);
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('vCreateIssue.notifications.success')
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async uploadFile(context, file: File): Promise<any> {
    try {
      return await createEntity(IssuesEndpoints.FILES, file);
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async deleteFiles(context, files: File[]): Promise<AxiosResponse> {
    try {
      return await deleteFiles(files)
    } catch(e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async getFiltersAndAllIssues({ commit }, params: FilterParams){
    try{
      const { data } = await getFiltersAndAllIssues(params)
      commit('issues/setIssues', data, { root: true });
    } catch(e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  }
};

export default actions;
