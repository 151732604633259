import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  height: "20.285",
  viewBox: "0 0 20.285 20.285",
  width: "20.285",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("svg", _hoisted_1, [
    _createVNode("path", {
      id: "icon-plus",
      d: "M10.142,0a1.6,1.6,0,0,0-1.6,1.6v6.94H1.6a1.6,1.6,0,1,0,0,3.2h6.94v6.94a1.6,1.6,0,1,0,3.2,0v-6.94h6.94a1.6,1.6,0,1,0,0-3.2h-6.94V1.6A1.6,1.6,0,0,0,10.142,0Z",
      "data-name": "icon-plus",
      fill: _ctx.open? '#505172':'#FFF' 
    }, null, 8, ["fill"])
  ]))
}