
import { defineComponent, ref } from 'vue';
import { useGlobalTranslations } from '@/hooks/use-translations';

import IconBack from '@/components/icons/icon-back.component.vue';

export default defineComponent({
  name: 'CButtonBack',
  components: { IconBack },
  setup() {
    const isFocused = ref(false);
    return {
      isFocused,
      useGlobalTranslations,
    }
  }
})
