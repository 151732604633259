
import { defineComponent, PropType } from 'vue';
import { FilterConfigurationFilters, IssueFiltersItem, SpecificFilters } from '@/views/issues/issues.types';
import { useTranslations } from '@/hooks/use-translations';
import { useGlobals } from '@/hooks/use-globals';

export default defineComponent({
  name: 'CIssuesSelectedFiltersStringTag',
  props: {
    filter: {
      type: Array as PropType<string[]>,
      required: true,
    },
    filterKey: {
      type: String,
      required: true,
    },
  },
  emits: ['delete-filter'],
  setup() {
    const { store } = useGlobals()

    const getLabel = (value: string, key: keyof FilterConfigurationFilters) => {
      let filter: IssueFiltersItem[] | undefined;
      if(key === 'assignedUsers') {
        filter = store.state.issues.options.users;
      } else if (key === 'otherGroups') {
        filter = store.state.issues.options.groups;
      } else {
        filter = store.state.issues.options[key] || store.state.issues.options.groups;
      }
      return Array.isArray(filter) && (filter.find((item) => item.uuid === value)?.name || value).toUpperCase()
    }

    return {
      SpecificFilters,
      useTranslations,
      getLabel
    }
  }
});
