
import { computed, ComputedRef, defineComponent } from 'vue';
import { useGlobals } from '@/hooks/use-globals';
import { useTranslations } from '@/hooks/use-translations';
import {
  DateRange,
  FilterConfigurationFilters,
  IssuePriority,
  IssueStatus, SelectedFilters,
  SpecificFilters
} from '@/views/issues/issues.types';
import CIssuesSelectedFiltersDateTag
  from '@/views/issues/components/issues-selected-filters/components/issues-selected-filters-date-tag.component.vue';
import CIssuesSelectedFiltersStringTags
  from '@/views/issues/components/issues-selected-filters/components/issues-selected-filters-string-tag.component.vue';
import { useIssues, UseIssuesContext } from '@/hooks/use-issues';
import { cloneDeep } from 'lodash-es';
import { WritableComputedRef } from '@vue/reactivity';
import { initialFilterState } from '@/views/issues/helpers/variables';

export default defineComponent({
  name: 'CIssuesSelectedFilters',
  components: { CIssuesSelectedFiltersStringTags, CIssuesSelectedFiltersDateTag },
  emits: ['updated-filters'],
  setup(props, { emit }) {
    const { store } = useGlobals();
    const { updateFiltersAndGetAllIssues } = useIssues(UseIssuesContext.ISSUES);
    const amountOfSelectedFilters: ComputedRef<number> = computed(() => store.getters['issues/amountOfSelectedFilters']);
    const filters: WritableComputedRef<SelectedFilters> = computed({
      get: () => store.getters['issues/selectedFiltersToDisplay'],
      set: (filter) => {
        store.state.issues.selectedIssueFilters = cloneDeep(filter);
      },
    });

    const handleStringDelete = async (event: { item: string, key: keyof Omit<SelectedFilters, 'dateRange' | 'createdDateRange' | 'issueFilterConfigurationUuid' | 'filterType'> }): Promise<void> => {
      if (event.key === 'deadlineOver') {
        filters.value[event.key] = false
      } else {
        filters.value[event.key] = filters.value[event.key].filter((item) => item !== event.item) as IssuePriority[] & IssueStatus[] & string[];
      }

      await updateFiltersAndGetAllIssues({ ...filters.value, filterType: store.state.issuesParams.type });
      emit('updated-filters');
    };

    const handleDateDelete = async (event: { key: keyof Pick<SelectedFilters, 'dateRange' | 'createdDateRange'>, value: keyof DateRange }): Promise<void> => {
      filters.value[event.key][event.value] = null
      await updateFiltersAndGetAllIssues({ ...filters.value, filterType: store.state.issuesParams.type });
      emit('updated-filters');
    };

    const clearAllFilters = (): void => {
      filters.value = cloneDeep(initialFilterState);
      updateFiltersAndGetAllIssues({ ...filters.value, filterType: store.state.issuesParams.type });
      emit('updated-filters');
    };

    return {
      useTranslations,
      filters,
      amountOfSelectedFilters,
      SpecificFilters,
      clearAllFilters,
      handleDateDelete,
      handleStringDelete,
    };
  },
});
