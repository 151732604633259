
import { computed, defineComponent, Ref, ref } from 'vue';
import { FilterTypes }                         from '@/types';
import { useGlobals }                          from '@/hooks/use-globals';
import { useIssues, UseIssuesContext }         from '@/hooks/use-issues';
import { useTranslations }                     from '@/hooks/use-translations';
import CSearchbar                              from '@/components/common/searchbar/searchbar.component.vue';
import CDropdown                               from '@/components/common/dropdown/dropdown.component.vue';
import CTableDropdownContent                   from '@/components/common/table/table-dropdown-content.component.vue';
import CIssuesSelectedFilters                  from '@/views/issues/components/issues-selected-filters/issues-selected-filters.component.vue';
import { ColouredStatus, IssueStatus, FilterParams }         from '@/views/issues/issues.types';
import { Tag }                                 from '@/components/common/table/table.types';
import getFiltersAndAllIssues               from '@/views/createIssue/store/createIssue.actions';

export default defineComponent({
  name: 'CLinkedIssuesFilters',
  components: { CIssuesSelectedFilters, CTableDropdownContent, CDropdown, CSearchbar },
  setup() {
    const { store } = useGlobals();
    const { updateFiltersAndGetAllIssues, getAllIssues } = useIssues(UseIssuesContext.ISSUES);
    const searchQuery = ref('');
    const selectedStatuses: Ref<Tag[]> = ref([]);
    const selectedTypes: Ref<Tag[]> = ref([]);

    const filters = computed(() => store.state.issues.filtersConfiguration.filters);
    const issueTypes = store.state.issues.filtersConfiguration.options.issueTypes;
    const statusList = store.state.issues.statusList.filter((status: ColouredStatus) => status.name !== IssueStatus.DONE && status.name !== IssueStatus.ENDED);

    const updateSelected = (type: string, event: Tag[]) => {
      switch (type) {
        case 'status':
          filters.value.issueStatuses = event.map((event) => {
            return event.name as IssueStatus;
          });
          selectedStatuses.value = event;
          break;
        case 'issueType':
          filters.value.issueTypes = event.map((event) => {
            return { uuid: event.uuid, name: event.name };
          });
          selectedTypes.value = event;
          break;
      }

      const filterParams: FilterParams = {
        type: filters.value.filterType,
        filter: 'ALL',
        time: false,
        issueStatuses: filters.value.issueStatuses
      };

      store.dispatch('views/vCreateIssue/getFiltersAndAllIssues', filterParams )

      //ToDo: Obsługa filtrowania issue type html + script
    };

    const updateSelectedAccordingToFilters = () => {
      selectedStatuses.value = selectedStatuses.value.filter((selectedStatus: Tag) => filters.value.issueStatuses.includes(selectedStatus.name));
      selectedTypes.value = selectedTypes.value.filter((selectedType: Tag) => filters.value.issueTypes.includes(selectedType.uuid));
    };

    const updateIssuesSearchQueryParam = (payload: string): void => {
      searchQuery.value = payload;
      store.commit('setIssueParamsSearchQuery', payload);
      getAllIssues();
    }

    return {
      FilterTypes,
      filters,
      selectedStatuses,
      selectedTypes,
      issueTypes,
      searchQuery,
      statusList,
      updateSelected,
      updateSelectedAccordingToFilters,
      updateIssuesSearchQueryParam,
      getFiltersAndAllIssues,
      useTranslations,
    };
  },
});
