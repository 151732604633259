
import { defineComponent, onUpdated, ref, Ref } from 'vue';
import CCloseButton                             from '@/components/common/close-button/close-button.component.vue';

export default defineComponent({
  name: 'CSidePanelWithBackground',
  components: { CCloseButton },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    }
  },
  emits: [ 'close-panel' ],
  setup(props, { emit }) {
    const panel: Ref<HTMLElement | null> = ref(null);
    onUpdated(() => {
      if (props.isVisible) {
        document.addEventListener('keyup', keyboardCallback);
      } else {
        document.removeEventListener('keyup', keyboardCallback);
      }
    });
    const keyboardCallback = (event: KeyboardEvent) => {
      if (event.key === 'escape') {
        closePanel();
      }
    };
    const closePanel = () => {
      emit('close-panel');
    };
    return { closePanel, panel };
  },
});
