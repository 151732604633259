import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-gray-600 mb-f5" }
const _hoisted_2 = { class: "flex justify-start items-center mb-f5 cursor-pointer" }
const _hoisted_3 = { class: "overflow-hidden justify-center relative items-center flex w-12 h-12 bg-primary-500 rounded-full cursor-pointer mx-f8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_calendar = _resolveComponent("icon-calendar")
  const _component_el_date_picker = _resolveComponent("el-date-picker")
  const _component_c_icon_trash_can = _resolveComponent("c-icon-trash-can")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("span", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createVNode("div", _hoisted_2, [
      _createVNode("input", {
        class: ["bg-gray-200 text-gray-600 p-f13 h-14 text-12 w-2/3 rounded-full focus:outline-none", _ctx.createIssueMode ? 'flex-grow' : ''],
        disabled: "",
        value: _ctx.modelValue
      }, null, 10, ["value"]),
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_icon_calendar, { class: "w-8 h-8 absolute cursor-pointer text-white" }),
        _createVNode(_component_el_date_picker, {
          modelValue: _ctx.date,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.date = $event)),
          class: "opacity-0 cursor-pointer absolute -left-1",
          clearable: false,
          disabled: _ctx.disabledCalendar,
          "disabled-date": false,
          editable: false,
          "value-format": "YYYY-MM-DD",
          onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', _ctx.date)))
        }, null, 8, ["modelValue", "disabled"])
      ]),
      _createVNode(_component_c_icon_trash_can, {
        class: "h-10 w-10 fill-current text-gray-850 hover:text-red-400",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:modelValue', '')))
      })
    ])
  ], 64))
}